export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [];

export const dictionary = {
		"/": [4],
		"/admin/users/[[searchParams]]": [8],
		"/auth": [9,[2]],
		"/auth/forgot-password": [10,[2]],
		"/auth/login": [12,[2]],
		"/auth/new-password/[token]": [11,[2]],
		"/comptes-bancaires/liste/[[searchParams]]": [7],
		"/contrats/liste/[[searchParams]]": [13],
		"/echances-a-facturer/liste/[[searchParams]]": [15],
		"/factures/liste/[[searchParams]]": [16],
		"/opcos/liste/[[searchParams]]": [5],
		"/paiements/liste/[[searchParams]]": [17],
		"/pecs/liste/[[searchParams]]": [6],
		"/user/profile": [14,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';